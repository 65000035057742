<template>
    <div>
        <Modal v-if="isModal" title="Add Ticket" @close="$emit('close')">
            <template #body>
                <div>
                    <input type="text" class="form-control" placeholder="Sender" v-model="ticket.sender">
                    <input type="text" class="form-control" placeholder="Title" v-model="ticket.title">
                    <textarea class="form-control" placeholder="Message" v-model="ticket.message"></textarea>
                </div>
            </template>
            <template #buttons>
                <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                <button type="button" class="btn btn-success" @click="saveNewTicket()">Save new Item</button>
            </template>
        </Modal>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import Modal from '@/components/Modal';

export default {
    name: 'AddTicketModal',
    components: {Modal},
    props: ['isModal'],
    data: () => ({
        ticket: {
            sender: '',
            message: '',
            title: '',
        }
    }),
    created() {
        this.ticket = {};
    },
    methods: {
        ...mapActions(['postManualTicket']),
        saveNewTicket() {
            this.postManualTicket(this.ticket).then(() => {
                this.$emit('close');
            });
        }
    }
};
</script>

<style scoped>

</style>