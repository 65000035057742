<template>
    <main class="d-flex w-100">
        <div class="container d-flex flex-column">
            <div class="row vh-100">
                <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                    <div class="d-table-cell align-middle">
                        <div class="text-center mt-4">
                            <h1 class="h2">
                                C3LF System3
                            </h1>
                            <p class="lead" v-if="msg">
                                {{ msg }}
                            </p>
                            <p class="lead" v-else>
                                Create an account to get started
                            </p>
                        </div>
                        <div class="card bg-dark">
                            <div class="card-body">
                                <div class="m-sm-4">
                                    <form role="form" method="post" @submit.prevent="do_register">
                                        <div :class="errors.username?['mb-3','is-invalid']:['mb-3']">
                                            <label class="form-label">Username</label>
                                            <div class="input-group">
                                                <input class="form-control"
                                                       type="text" v-model="form.username" id="validationCustomUsername"
                                                       placeholder="Enter your username" required/>
                                            </div>
                                            <div class="invalid-feedback">
                                                {{ errors.username }}
                                            </div>
                                        </div>

                                        <div :class="errors.email?['mb-3','is-invalid']:['mb-3']">
                                            <label class="form-label">E-Mail</label>
                                            <div class="input-group">
                                                <input class="form-control"
                                                       type="email" v-model="form.email" id="validationCustomEmail"
                                                       placeholder="Enter your email" required/>
                                            </div>
                                            <div class="invalid-feedback">
                                                {{ errors.email }}
                                            </div>
                                        </div>


                                        <div :class="errors.password?['mb-3','is-invalid']:['mb-3']">
                                            <label class="form-label">Password</label>
                                            <input class="form-control" type="password"
                                                   v-model="form.password" placeholder="Enter your password"/>
                                            <div class="invalid-feedback">{{ errors.password }}</div>
                                        </div>

                                        <div :class="errors.password2?['mb-3','is-invalid']:['mb-3']">
                                            <label class="form-label">Password Check</label>
                                            <input class="form-control" type="password"
                                                   v-model="password2" placeholder="Enter your password again"/>
                                            <div class="invalid-feedback">{{ errors.password2 }}</div>
                                        </div>

                                        <div class="text-center mt-3">
                                            <button type="submit" class="btn btn-primary">
                                                Register
                                            </button>
                                        </div>
                                    </form>
                                    <br/>
                                    <div class="text-center">
                                        <p class="mb-0 text-muted">
                                            Already have an account?
                                            <router-link to="/login">Login</router-link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

export default {
    name: 'Register',
    data() {
        return {
            msg: 'Register as team member',
            password2: '',
            form: {
                username: '',
                password: '',
                email: '',
            },
            errors: {
                username: null,
                password: null,
                password2: null,
                email: null,
            }
        }
    },
    methods: {
        do_register() {
            console.log('do_register');
            console.log(this.form);
            if (this.form.password !== this.password2) {
                this.errors.password2 = 'Passwords do not match';
                return;
            } else {
                this.errors.password2 = null;
            }
            fetch('/api/2/register/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.form)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.errors) {
                        console.error('Error:', data.errors);
                        this.errors = data.errors;
                        return;
                    }
                    console.log('Success:', data);
                    this.msg = 'Success';
                    this.$router.push('/login');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    this.msg = 'Error';
                });
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.is-invalid input, .is-invalid select {
    border: 1px solid var(--danger);
}

.is-invalid .invalid-feedback {
    display: block;
}

input {
    background-color: var(--dark);
    border: var(--gray) 1px solid;;

    &:focus {
        background-color: var(--dark);
    }

    &:hover {
        background-color: var(--dark);
    }

    &[type="checkbox"] {
        background-color: var(--dark);
    }
}
</style>