<template>
    <input
        class="form-control w-100"
        type="search"
        placeholder="Search"
        aria-label="Search"
        v-model="search_query"
        @keyup.enter="dispatchSearch"
    >
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import router from "@/router";

export default {
    name: 'SearchBox',
    data() {
        return {
            search_query: ''
        }
    },
    computed: {
        ...mapGetters(['getActiveView', 'route']),
    },
    watch: {
        route() {
            this.search_query = this.route.params.search || '';
        }
    },
    methods: {
        ...mapActions(['searchEventItems', 'searchEventTickets']),
        isItemView() {
            return this.getActiveView === 'items' || this.getActiveView === 'item' || this.getActiveView === 'item_search';
        },
        isTicketView() {
            return this.getActiveView === 'tickets' || this.getActiveView === 'ticket' || this.getActiveView === 'ticket_search';
        },
        dispatchSearch() {
            if (this.isItemView()) {
                router.push({
                    name: "item_search",
                    query: this.route.query,
                    params: {...this.route.params, search: this.search_query}
                });
            } else if (this.isTicketView()) {
                router.push({
                    name: "ticket_search",
                    query: this.route.query,
                    params: {...this.route.params, search: this.search_query}
                });
            }
        }
    }
};
</script>