<template>
    <AsyncLoader :loaded="events.length > 0">
        <ExpandableTable v-if="!!events" :columns="['slug', 'name']" :items="events.map((e,i)=>({idx: i, ...e}))"
                         :keyName="'slug'">
            <template v-slot:header_actions>
                <button class="btn btn-success" @click.prevent="openAddEventModal">
                    <font-awesome-icon icon="plus"/>
                    Create Event
                </button>
            </template>
            <template v-slot:actions="{ item }">
                <div class="btn-group">
                    <button class="btn btn-secondary" @click.stop="changeEvent(item)" style="white-space: nowrap;">
                        <font-awesome-icon icon="archive"/>&nbsp;use
                    </button>
                    <button class="btn btn-danger" @click.stop="safeDeleteEvent(item.eid)" style="white-space: nowrap;">
                        <font-awesome-icon icon="trash"/>&nbsp;delete
                    </button>
                </div>
            </template>
            <template v-slot:detail="{id, item }">
                <div class="row">
                    <div class="col">
                        <input type="date" class="form-control form-control-sm" title="Buildup Start"
                               v-model="item.pre_start" disabled style="opacity: 1"
                               :max="item.start" @focus="prepare_date_field">
                    </div>
                    <div class="col">
                        <input type="date" class="form-control form-control-sm" title="Official Event Start"
                               v-model="item.start" disabled style="opacity: 1"
                               :min="item.pre_start" :max="item.end" @focus="prepare_date_field">
                    </div>
                    <div class="col">
                        <input type="date" class="form-control form-control-sm" title="Official Event End"
                               v-model="item.end" disabled style="opacity: 1"
                               :min="item.start" :max="item.post_end" @focus="prepare_date_field">
                    </div>
                    <div class="col">
                        <input type="date" class="form-control form-control-sm" title="Teardown End"
                               v-model="item.post_end" disabled style="opacity: 1"
                               :min="item.end" @focus="prepare_date_field">
                    </div>
                </div>
                <div class="mt-3">
                    <label class="mr-3">Addresses: </label>
                    <div v-for="(address, a_id) in item.addresses" class="btn-group btn-group-sm mr-3"
                         @click.stop="deleteAddress(item.idx, a_id)">
                        <button class="btn btn-secondary" disabled style="opacity: 1">
                            {{ address }}
                        </button>
                        <button class="btn btn-danger">
                            <font-awesome-icon icon="trash"/>
                        </button>
                    </div>
                    <div class="btn-group btn-group-sm">
                        <input type="text" v-model="new_address[item.idx]">
                        <button class="btn btn-secondary" @click.stop="addAddress(item.idx)"
                                style="white-space: nowrap;">
                            <font-awesome-icon icon="envelope"/>&nbsp;add
                        </button>
                    </div>
                </div>
            </template>
        </ExpandableTable>
    </AsyncLoader>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import ExpandableTable from "@/components/ExpandableTable.vue";
import AsyncLoader from "@/components/AsyncLoader.vue";

export default {
    name: 'Events',
    components: {AsyncLoader, ExpandableTable},
    computed: mapState(['events']),
    data() {
        return {new_address: []}
    },
    methods: {
        ...mapActions(['changeEvent', 'deleteEvent', 'updateEvent']),
        ...mapMutations(['openAddEventModal']),
        safeDeleteEvent(id) {
            if (confirm('do you want to completely delete this event and related data?')) {
                this.deleteEvent(id)
            }
        },
        addAddress(id) {
            const a = this.new_address[id];
            if (!this.events[id].addresses.includes(a))
                this.events[id].addresses.push(a)
            this.new_address[id] = ""
            this.updateEvent({id: this.events[id].id, partial_event: {addresses: this.events[id].addresses}});
        },
        deleteAddress(id, a_id) {
            this.events[id].addresses = this.events[id].addresses.filter((e, i) => i !== a_id);
            this.updateEvent({id: this.events[id].id, partial_event: {addresses: this.events[id].addresses}});
        }
    },
};
</script>

<style scoped>
</style>