<template>
    <div>
        <Modal v-if="isModal" title="Add Item" @close="$emit('close')">
            <template #body>
                <div>
                    <InputPhoto
                        :model="item"
                        field="file"
                        :on-capture="storeImage"
                    />
                    <InputString
                        label="description"
                        :model="item"
                        field="description"
                        :validation-fn="str => str && str.length > 0"
                    />
                    <div class="form-group">
                        <label for="box">box</label>
                        <InputCombo
                            label="box"
                            :model="item"
                            nameKey="box"
                            uniqueKey="cid"
                            :options="boxes"
                        />
                    </div>
                </div>
            </template>
            <template #buttons>
                <button type="button" class="btn btn-secondary" @click="$emit('close')">Cancel</button>
                <button type="button" class="btn btn-success" @click="saveNewItem()">Save new Item</button>
            </template>
        </Modal>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Modal from '@/components/Modal';
import InputCombo from "@/components/inputs/InputCombo.vue";
import InputPhoto from "@/components/inputs/InputPhoto.vue";
import InputString from "@/components/inputs/InputString.vue";

export default {
    name: 'AddItemModal',
    components: {InputString, InputPhoto, InputCombo, Modal},
    props: ['isModal'],
    data: () => ({
        item: {}
    }),
    computed: {
        ...mapState(['lastUsed']),
        ...mapGetters(['getBoxes']),
        boxes({getBoxes}) {
            return getBoxes.map(obj => ({cid: obj.id, box: obj.name}));
        }
    },
    methods: {
        ...mapActions(['postItem', 'loadBoxes', 'scheduleAfterInit']),
        async saveNewItem() {
            await this.postItem(this.item);
            this.$emit('close');
        },
        storeImage(image) {
            this.item.dataImage = image;
        }
    },
    mounted() {
        this.scheduleAfterInit(() => [this.loadBoxes().then(() => {
            this.item = {box: this.lastUsed.box || '', cid: this.lastUsed.cid || ''};
        })])
    }
};
</script>

<style scoped>

</style>