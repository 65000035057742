<template>
    <AsyncLoader :loaded="isItemsLoaded">
        <div class="container-fluid px-xl-5 mt-3">
            <div class="row" v-if="layout === 'table'">
                <div class="col-xl-8 offset-xl-2">
                    <Table
                        :columns="['id', 'description', 'box']"
                        :items="getEventItems"
                        :keyName="'id'"
                        @itemActivated="showItemDetail"
                    >
                        <template #actions="{ item }">
                            <div class="btn-group">
                                <button class="btn btn-success"
                                        @click.stop="confirm('return Item?') && markItemReturned(item)"
                                        title="returned">
                                    <font-awesome-icon icon="check"/>
                                </button>
                                <button class="btn btn-secondary" @click.stop="openEditingModalWith(item)" title="edit">
                                    <font-awesome-icon icon="edit"/>
                                </button>
                                <button class="btn btn-danger" @click.stop="confirm('delete Item?') && deleteItem(item)"
                                        title="delete">
                                    <font-awesome-icon icon="trash"/>
                                </button>
                            </div>
                        </template>
                    </Table>
                </div>
            </div>
            <Cards
                v-if="layout === 'cards'"
                :columns="['id', 'description', 'box']"
                :items="getEventItems"
                :keyName="'id'"
                v-slot="{ item }"
                @itemActivated="item => openLightboxModalWith(item.file)"
            >
                <AuthenticatedImage v-if="item.file" cached
                                    :src="`/media/2/256/${item.file}/`"
                                    class="card-img-top img-fluid"
                />
                <div class="card-body">
                    <h6 class="card-title">{{ item.description }}</h6>
                    <h6 class="card-subtitle text-secondary">id: {{ item.id }} box: {{ item.box }}</h6>
                    <div class="row mx-auto mt-2">
                        <div class="btn-group">
                            <button class="btn btn-outline-success"
                                    @click.stop="confirm('return Item?') && markItemReturned(item)" title="returned">
                                <font-awesome-icon icon="check"/>
                            </button>
                            <button class="btn btn-outline-secondary" @click.stop="showItemDetail(item)"
                                    title="edit">
                                <font-awesome-icon icon="edit"/>
                            </button>
                            <button class="btn btn-outline-danger"
                                    @click.stop="confirm('delete Item?') && deleteItem(item)"
                                    title="delete">
                                <font-awesome-icon icon="trash"/>
                            </button>
                        </div>
                    </div>
                </div>
            </Cards>
        </div>
    </AsyncLoader>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import Table from '@/components/Table';
import Cards from '@/components/Cards';
import Modal from '@/components/Modal';
import AuthenticatedImage from "@/components/AuthenticatedImage.vue";
import AsyncLoader from "@/components/AsyncLoader.vue";
import router from "@/router";

export default {
    name: 'Items',
    data: () => ({
        lightboxHash: null,
        editingItem: null,
    }),
    components: {AsyncLoader, AuthenticatedImage, Table, Cards, Modal},
    computed: {
        ...mapGetters(['getEventItems', 'isItemsLoaded', 'layout', 'getEventSlug']),
    },
    methods: {
        ...mapActions(['deleteItem', 'markItemReturned', 'loadEventItems', 'updateItem', 'scheduleAfterInit']),
        ...mapMutations(['openLightboxModalWith']),
        showItemDetail(item) {
            router.push({name: 'item', params: {id: item.id}});
        },
        confirm(message) {
            return window.confirm(message);
        }
    },
    watch: {
        getEventSlug() {
            this.scheduleAfterInit(() => [this.loadEventItems()]);
        }
    },
    mounted() {
        this.scheduleAfterInit(() => [this.loadEventItems()]);
    }
};
</script>

<style scoped>

</style>